import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Modul } from '../../models/modul';
import { SharedService } from '../../services/shared.service';
import { AppComponent } from 'src/app/app.component';

declare var System: any;
declare var require: any;


@Component({
  selector: 'app-navigationbuilder',
  providers: [AppComponent],
  templateUrl: './navigationbuilder.component.html',
  styleUrls: ['./navigationbuilder.component.css']
})

export class NavigationbuilderComponent implements OnInit {

  @Input() navmodule: Modul[] = []

  constructor(private cdRef: ChangeDetectorRef, private dataService: DataService, private sharedService: SharedService) {
    this.navmodule = []

  }

  ngOnInit() {
    this.getModule()
    //this.globalEventsManager.showNavBar('admin');
    this.dataService.setNavState('admin');

  }

  storeTemp(): void {
    // console.log("store")
    localStorage.setItem('navbuildertemp', JSON.stringify(this.navmodule))

  }

  loadTemp(): void {
    // console.log("load")
    this.navmodule = JSON.parse(localStorage.getItem('navbuildertemp'))

  }

  getModule(): void {

    this.navmodule = this.sharedService.getNavArray()

    if (this.navmodule.length == 0) {
      // console.log("load new " + this.navmodule.length)
      //this.modulpageService.getModule('wm').then(module => this.navmodule = module)
    }

  }

  createNavigationTree(): void {
    //for (let entry of this.module) {
    // console.log(JSON.stringify(this.navmodule))
    //}
  }

  delete(thismodule: Modul): void {
    //for (let entry of this.module) {
    // console.log("delete " + thismodule.id)
    this.removeModule(thismodule, this.navmodule)
    // console.log("stored " + this.navmodule.length)
    //this.sharedService.setNavArray(this.navmodule)
    //window.location.reload();
    this.cdRef.detectChanges()
    //}
  }

  generateIdForLevel(level: number): number {
    return 1
  }

  createModule(thismodule: Modul, withSubmodule: boolean): void {
    /*
        var level: number = thismodule.level
    
        // console.log("create for " + thismodule.id)
    
        var neuesmodul: Modul
        var newid = this.generateIdForLevel(level)
    
        if (withSubmodule) {
          var neuessubmodul: Modul
    
          neuessubmodul = { id: 1099, level: level + 1, parent: 0, name: 'NEU SUB', link: '', displayname: '', mandatoryAggregate: [1000], submodule: [] }
          neuesmodul = { id: newid, level: level, parent: thismodule.id, name: 'NEU', link: '123.pdf', mandatoryAggregate: [1000], displayname: '', submodule: [neuessubmodul] }
          neuessubmodul.parent = neuesmodul.id
    
        } else {
          neuesmodul = { id: newid, level: 3, parent: thismodule.id, name: 'NEU', link: '123.pdf', mandatoryAggregate: [1000], displayname: '', submodule: [] }
    
        }
    
        this.addModuleAtPosition(thismodule, neuesmodul)
        // console.log("stored " + this.navmodule.length)
        //this.sharedService.setNavArray(this.navmodule)
        //window.location.reload();
        this.cdRef.detectChanges()
        //}
        */
  }

  removeModule(modul: Modul, modularray: Modul[]) {

    for (let entry of modularray) {
      let index: number = modularray.indexOf(modul);

      if (index !== -1) {
        // console.log("deleted")
        modularray.splice(index, 1);
      } else {
        this.removeModule(modul, entry.submodule)
      }
    }

  }

  addModule(modul: Modul) {
    let index: number = this.navmodule.indexOf(modul);
    if (index == -1) {
      this.navmodule.push(modul);
    }
  }

  addModuleAtPosition(modul: Modul, newmodul: Modul) {

    var returnmodule: Modul[] = []

    for (let entry of this.navmodule) {
      // console.log("1 " + entry.id)

      for (let subentry of entry.submodule) {
        // console.log("2 " + subentry.id)

        for (let subsubentry of subentry.submodule) {
          // console.log("3 " + subsubentry.id)

          if (subsubentry.id === modul.id) {
            subentry.submodule.push(newmodul);
          }
        }

        if (subentry.id === modul.id) {
          entry.submodule.push(newmodul);
        }

      }

      returnmodule.push(entry);

      if (entry.id === modul.id) {
        returnmodule.push(newmodul);
      }
    }

    // console.log(returnmodule)
    this.exportNavFile()

    this.navmodule = returnmodule
    this.cdRef.detectChanges()
  }

  exportNavFile(): void {
    /*
     var pptx = require('pptxgenjs');
     pptx.setAuthor('Markus Schramm');
     pptx.setCompany('objectix Software Solutions GmbH');
     pptx.setRevision('15');
     pptx.setSubject('NavDefinition for combook');
     pptx.setTitle('NavDefinition for combook');
 
     pptx.setLayout({ name:'A3', width:11.7, height:16.5 });
 
     var slide = pptx.addNewSlide();
     
     slide.addText('Navigationsdefinition', { x:0.5, y:0.25, font_size:18, font_face:'Arial', color:'0088CC' });
     
     slide = pptx.addNewSlide();
     slide.addText(JSON.stringify(this.navmodule), { valign: 't', x:0.5, y:0.25, font_size:8, font_face:'Arial', color:'0088CC' });
   
     pptx.save("navigation.pptx")
     */
  }
}
