export class Vertriebsbox {
  storename: string;
  id: number;
  parent: number;
  name: string;
  content: string;
  template: string;
  image: string;
  link1: string;
  link2: string;
  link3: string;
  link4: string;
  link1titel: string;
  link2titel: string;
  link3titel: string;
  link4titel: string;


  // tslint:disable-next-line:max-line-length
  constructor(id: any, parent: any, name: any, content: string, template: any, image: string, link1titel: any, link2titel: any, link3titel: any, link4titel: any, link1: any, link2: any, link3: any, link4: any) {
    this.id = id;
    this.parent = parent;
    this.name = name;
    this.content = content;
    this.template = template;
    this.image = image;
    this.link1titel = link1titel;
    this.link2titel = link2titel;
    this.link3titel = link3titel;
    this.link4titel = link4titel;
    this.link1 = link1;
    this.link2 = link2;
    this.link3 = link3;
    this.link4 = link4;
  }



  getBoxtype() {

    if (this.template == '1') {
      // tslint:disable-next-line:max-line-length
      return '<aside><p><span style="font-size:12px"><strong>' + this.name + '</strong></span></p><p>&nbsp;</p><p><span style="font-size:10px">' + this.content + '</span></p><p><br/><img src="./assets/images/pfeil_gelb.png"><b> <a href="' + this.link1 + '" target="_blank">' + this.link1titel + '</a></b><br><img src="./assets/images/pfeil_gelb.png"> <b><a href="' + this.link2 + '" target="_blank">' + this.link2titel + '</a></b></p></aside>';
    } else if (this.template == '2') {
      // tslint:disable-next-line:max-line-length
      return '<aside><p><span style="font-size:12px"><strong>' + this.name + '</strong></span></p><p>&nbsp;</p><p><span style="font-size:10px">' + this.content + '</span></p><p>&nbsp;</p></aside>';
    } else if (this.template == '3') {
      // tslint:disable-next-line:max-line-length
      return '<aside><p><span style="font-size:12px"><strong>' + this.name + '</strong></span></p><p><br/><img src="./assets/images/pfeil_gelb.png"><b> <a href="' + this.link1 + '" target="_blank">' + this.link1titel + '</a></b><br><img src="./assets/images/pfeil_gelb.png"> <b><a href="' + this.link2 + '" target="_blank">' + this.link2titel + '</a></b><br/><img src="./assets/images/pfeil_gelb.png"><b> <a href="' + this.link3 + '" target="_blank">' + this.link3titel + '</a></b><br><img src="./assets/images/pfeil_gelb.png"> <b><a href="' + this.link4 + '" target="_blank">' + this.link4titel + '</a></b></p></aside>';
    } else if (this.template == '4') {
      // tslint:disable-next-line:max-line-length
      return '<aside><p><span style="font-size:12px"><strong>' + this.name + '</strong></span></p><p><img src="./assets/images/' + this.image + '.png"></p><p>&nbsp;</p><p><span style="font-size:10px">' + this.content + '</span></p><p><br/><img src="./assets/images/pfeil_gelb.png"> <b><a href="' + this.link1 + '" target="_blank">' + this.link1titel + '</a></b></p></aside>';
    } else if (this.template == '5') {
      // tslint:disable-next-line:max-line-length
      return '<aside><p><span style="font-size:12px"><strong>' + this.name + '</strong></span></p><p><img src="./assets/images/' + this.image + '.png"></p><p>&nbsp;</p><p><span style="font-size:10px">' + this.content + '</span></p><p><br/>';
    } else if (this.template == '6') {
      // tslint:disable-next-line:max-line-length
      return '<aside><p><span style="font-size:12px"><strong>' + this.name + '</strong></span></p><p><center><img src="./assets/images/' + this.image + '.png" width="160px"><center></p>';
    } else if (this.template == '7') {
      // tslint:disable-next-line:max-line-length
      return '<aside><p><span style="font-size:12px"><strong>' + this.name + '</strong></span></p><p>&nbsp;</p><p><span style="font-size:10px">' + this.content + '</span></p><p><br/><img src="./assets/images/pfeil_gelb.png"><b> <a href="' + this.link1 + '" target="_blank">' + this.link1titel + '</a></b><br></p></aside>';
    }
  }
}
