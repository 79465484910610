import { Injectable } from '@angular/core';
import { Modul } from '../models/modul';

@Injectable()
export class SharedService {

    private selectionArray: Modul[] = []
    private navArray: Modul[] = []

    textblock: string[] = []

    constructor() {
        this.navArray = []
    }

    setTextblock(context: string, ausgangslage) {
        // console.log(ausgangslage)
        this.textblock[context] = ausgangslage
    }

    getTextblock(context: string) {
        return this.textblock[context]
    }

    setValue(context: string, val) {
        this.selectionArray[context] = val;
        // console.log(context + " > " + this.selectionArray[context])
    }

    getValue(context: string) {

        if (this.selectionArray[context] != undefined) {
            // console.log(context + " < " + this.selectionArray[context])
            return this.selectionArray[context];
        }
        return [];
    }

    setNavArray(val) {
        this.navArray = val;
    }

    getNavArray() {
        return this.navArray;
    }

    forFavorite(context: string) {

        var favString: string = ''

        for (let entry of this.getValue(context)) {
            favString += entry.id + ';'
        }
        //favString = favString.substr(0, favString.length-1)
        // console.log("favString: " + context + " " + favString)
        return favString
    }
}