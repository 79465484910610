export class Customerdata {
  id?: number
  anrede?: string = '';
  titel?: string = '';
  kundenname?: string = '';
  firmenname?: string = ''
  ortdesgespraechs?: string = '';
  beratername?: string = '';
  wmstandort?:string = '';
  datumdesgespraechs?: Date
}

