import { Injectable } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { UKPAGES } from '../definitions_/ukpages';

@Injectable()
export class UKService {
  getUKpages(): Promise<Mainpage[]> {
    return Promise.resolve(UKPAGES);
  }
}
