import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FavoritenpageComponent } from './favoritenpage/favoritenpage.component';

const routes: Routes = [{
    path: 'favoriten',
    component: FavoritenpageComponent,
    children: []
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class FavoritenpageRoutingModule { }
