import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Mainpage } from '../../models/mainpage';
import { BrowserModule } from '@angular/platform-browser';
import { MainpageService } from '../mainpage.service';
import { GlobalEventsManager } from '../../services/GlobalEventsManager';
import { Commaster } from '../../models/commaster';
import { Metadata } from '../../models/metadata';
import { Clipboard } from 'ts-clipboard';
import { DataService } from 'src/app/data.service';


declare var System: any;
declare var require: any;

@Component({
  selector: 'my-app',
  providers: [MainpageService],
  templateUrl: 'mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})

export class MainpageComponent extends Commaster implements OnInit {

  title = 'Ihre Anwendung für individuelle Beratungspräsentationen';
  private isLoading = false;
  mainpages: Mainpage[];
  selectedMainpage: Mainpage;
  private bodyText: string;
  public static startImage: string;


  constructor(private mainService: MainpageService, private globalEventsManager: GlobalEventsManager, public dataService: DataService) {
    super(dataService);
    this.isLoading = false;
    //this.startImagePath = this.startImage

  }

  getMainpages(): void {
    this.mainService.getMainpages().then(mainpages => this.mainpages = mainpages);
  }

  ngOnInit(): void {

    //console.log("Mainpage init")
    this.dataService.setNavState('main')

    localStorage.setItem('wm_module', '');
    localStorage.setItem('uk_module', '');
    localStorage.setItem('ik_module_en', '');
    localStorage.setItem('ik_module_de', '');

    this.getMainpages();
    this.dataService.setModules("LANGUAGE", 'de')

    //this.globalEventsManager.showNavBar('main');
    this.dataService.setNavState(this.context);

    //this.bodyText = 'This text can be updated in modal 1';
    this.isLoading = false;
  }

  setMainpage(mainpage) {


    // console.log("====================")
    // console.log("setMainpage")
    // console.log(mainpage)
    this.storeContext(mainpage.navigation);
    // console.log("====================")

    // console.log("XX");
    //this.storeContext('ik');
    // console.log("Set Mainpage")
    // console.log(mainpage)
    this.dataService.setModules("AUSWAHL", undefined)
    this.dataService.setModules("MAINPAGE", mainpage)
    this.dataService.setNavState(mainpage.navigation)
  }

  copyToClipboard(metadata: Metadata) {
    Clipboard.copy('JSON.stringify(metadata)')
    // console.log("metadata copied to clipboard ")
  }

  copyTo() {
    Clipboard.copy("HalliHallo")
    // console.log("metadata copied to clipboard ")
  }

  onSelect(mainpage: Mainpage): void {
    this.selectedMainpage = mainpage;
  }
}
