export class Modul {

  id: number;
  toggle?: Number;
  parent: number;
  level: number;
  name: string;
  path?: string;
  link: string;
  displayname: string;
  mandatoryAggregate: number[] = [];
  vertriebsbox?: string;
  regulatory?: string;
  mandatoryinfo?: string = '';
  submodule: Modul[];
  showSubTree?: boolean;
  showMeUp?: boolean;
  showMeDown?: boolean;
  is_checked?: boolean;
  value: number = 0
  farbe: string

  

}
