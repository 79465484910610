import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminpageComponent } from './adminpage/adminpage.component';
import { AdminpageRoutingModule } from './adminpage-routing.module';

@NgModule({
  imports: [
    CommonModule,
    AdminpageRoutingModule
  ],
  declarations: [AdminpageComponent]
})

export class AdminpageModule implements OnInit{ 

    constructor() { 
      
    }
    
    ngOnInit(): void {
      
    }
}
