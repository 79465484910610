import { Component, OnInit } from '@angular/core';
import { Mainpage } from '../../models/mainpage';
import { BrowserModule } from '@angular/platform-browser';
import { SharedService } from '../../services/shared.service';
import { AdminpageService } from '../adminpage.service';
import { GlobalEventsManager } from '../../services/GlobalEventsManager';
import { Commaster } from '../../models/commaster';
import { DataService } from 'src/app/data.service';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'my-app',
  providers: [AppComponent, AdminpageService],
  templateUrl: 'adminpage.component.html',
  styleUrls: ['adminpage.component.css']
})


export class AdminpageComponent extends Commaster implements OnInit {

  title = 'Adminecke';

  adminpages: Mainpage[];
  selectedAdminpage: Mainpage;

  isActive: true;

  speicherauswertung: string = "";
  speicherinhalt: string = "";

  // tslint:disable-next-line:max-line-length
  constructor(public dataService: DataService, private adminService: AdminpageService, private sharedService: SharedService, private globalEventsManager: GlobalEventsManager) {
    super(dataService);
  }

  getAdminpages(): void {
    this.adminService.getAdminpages().then(adminpages => this.adminpages = adminpages);
  }

  ngOnInit(): void {
    this.getAdminpages();

    this.speicherauswertung = localStorage.length + " ";
    this.speicherinhalt = "<b>Context</b>: " + this.getContext()
      + "<br/><b>ModuleMode</b>: " + this.getModulemode()
      + "<br/><b>selection</b>: " + localStorage.getItem('selection' + this.getModulemode())
      + "<br/><b>selectionModel</b>: " + localStorage.getItem('selectionModel' + this.getModulemode())
      + "<br/><b>idliste</b>: " + localStorage.getItem('idliste' + this.getModulemode())
      + "<br/><b>teamfolien</b>: " + localStorage.getItem('teamfolien')
      + "<br/><b>Language</b>: " + this.getLanguage()
      + "<br/><b>metadata</b>: " + localStorage.getItem("metadata" + this.getContext());

  }

  /*
    getDump() {
      this.globalEventsManager.showNavBar('admin');
      // console.log(">" + this.module);
      if (this.module!== undefined) {
        // console.log("XX" + this.getModulemode());
        var selectionmodel = this.restoreSelectionModelFromIdList(this.module, this.getModulemode());
  
        this.speicherinhalt = "<b>Context</b>: " + this.getContext()
        + "<br/><b>ModuleMode</b>: " + this.getModulemode()
        + "<br/><b>selection</b>: " + localStorage.getItem('selection' + this.getModulemode())
        + "<br/><b>idliste</b>: " + localStorage.getItem('idliste' + this.getModulemode())
        + "<br/><b>teamfolien</b>: " + localStorage.getItem('teamfolien')
        + "<br/><b>selectionModel</b>: " + selectionmodel
      }
      //
  
     // + "<br/><b>selectionModel</b>: " + selectionmodel
      /*
      this.speicherinhalt = "<b>Context</b>: " + this.getContext()
      + "<br/><b>ModuleMode</b>: " + this.getModulemode()
      + "<br/><b>selection</b>: " + localStorage.getItem('selection' + this.getModulemode())
      + "<br/><b>idliste</b>: " + localStorage.getItem('idliste' + this.getModulemode())
      + "<br/><b>teamfolien</b>: " + localStorage.getItem('teamfolien')
      + "<br/><b>Language</b>: " + this.getLanguage()
      + "<br/><b>metadata</b>: " + localStorage.getItem("metadata");
  
      return this.speicherinhalt;
    }
  */
  onSelect(mainpage: Mainpage): void {
    this.selectedAdminpage = mainpage;
  }
}



