import { Component, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Modul } from './models/modul';

@Component({
  selector: 'my-app',
  host: { 'window:beforeunload': 'doSomething' },
  providers: [],
  template: `
        <router-outlet></router-outlet>
    `
})


@Injectable()
export class AppComponent {

  spinner = 'sk-fading-circle';
  title = '';

  constructor(private storage: Storage) {
    storage.create()
    //localStorage.setItem('modulewm_module', '');
  }

  doSomething() {
    // console.log("refresh");
  }

  getModuleById(id: Number, module: Modul[]): Modul {
    for (let entry of module) {
      if (entry.id === id) {
        //console.log( "found1");
        this.spinner = '';
        return entry;
      }
      for (let subentry of entry.submodule) {
        if (subentry.id === id) {
          //console.log( "found2");
          this.spinner = '';
          return subentry;
        }
        for (let subsubentry of subentry.submodule) {
          if (subsubentry.id === id) {
            //console.log( "found3");
            this.spinner = '';
            return subsubentry;
          }
        }
      }
    }
    this.spinner = '';
    return null;
  }


  private onShowNavbar(data: any): void {
    /* --> HERE: you tell the global event manger to show the nav bar */
    //this.globalEventsManager.showNavBar(true);
  }
}