import { Component, OnInit, ViewChild, ElementRef, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Modul } from '../../models/modul';
import { ModulpageService } from '../modulpage.service';
//import { AppComponent } from '../../app.component';
import { GlobalEventsManager } from '../../services/GlobalEventsManager';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Commaster } from '../../models/commaster';
import { DataService } from 'src/app/data.service';
import { Mainpage } from 'src/app/models/mainpage';

//import { WindowRef } from '../../dialog/dialog.service';

@Component({
  selector: 'app-my-module',
  providers: [],
  templateUrl: './modulpage.component.html',
  styleUrls: ['./modulpage.component.css']
})

export class ModulpageComponent extends Commaster implements OnInit {

  pfad = ""

  vetoMap: VetoMap[] = []

  modulauswahlzurueck = ''
  auswahlaufheben = ''
  auswahleinblenden = ''

  sprache = 'de'
  title_modul = 'Modulauswahl';
  subtitle_modul = 'Wählen Sie die passenden Module für die Bedürfnisse Ihres Kunden';

  title_modul_en = 'Modules';
  subtitle_modul_en = 'Choose the right modules for your customer’s needs';

  showNavigation = true;
  sprachzusatz = '';
  backlink = '';
  weiter = ''

  sub: Subscription;
  fresh: number;

  modal: number = -1;
  regulatory = '';
  currentModul: Modul;

  selectionArray: Modul[] = [];
  selectionTree: Modul[] = [];
  tempHeaderArray: number[] = [];
  tempArray: number[] = [];

  regulatoryAccepted = false;
  buttonColor = 3;

  // 2022-09 the contextinfo using the dataService
  mainpage
  plotmodules = ''

  //2023-11-09
  // Decide to let the subtree open or not by clicking another node
  // -> toggleTreeBehaviour()
  remainNodeOpen: boolean = false

  modaldialog = '';
  nativeWindow: any;

  constructor(
    //private winRef: WindowRef,
    private route: Router,
    private modulpageService: ModulpageService,
    public dataService: DataService,
    @Inject(DOCUMENT) private document,
    //private globalEventsManager: GlobalEventsManager,

  ) {

    super(dataService);
    // console.log('ModulpageComponent constructor ' + this.context + '-' + this.modulemode);
    this.modaldialog = this.httpGetHTML('./assets/data/wettbewerber.htm');
    //this.nativeWindow = winRef.getNativeWindow();
    this.pfad = this.route.url.substring(1)
    console.log("Modulpage path: " + this.pfad)
    this.dataService.setActiveModule(this.pfad)
  }

  toggleTreeBehaviour() {
    // console.log('TOGGLE')
    this.remainNodeOpen = !this.remainNodeOpen
  }

  colorizeAndCheckTree() {

    const uncolored = ''
    const colored = '#d59001'

    this.module.forEach((m) => {
      m.farbe = uncolored
      if (m.submodule !== undefined) {
        for (const lev1 of m.submodule) {

          lev1.farbe = uncolored

          if (lev1.submodule !== undefined) {
            for (const lev2 of lev1.submodule) {

              lev2.farbe = uncolored

              if (lev2.submodule !== undefined) {
                for (const lev3 of lev2.submodule) {

                  lev3.farbe = uncolored

                  if (lev3.submodule !== undefined) {
                    for (const lev4 of lev3.submodule) {

                      lev4.farbe = uncolored


                    }
                  }
                }
              }
            }
          }
        }
      }
    })


    this.module.forEach((m) => {

      if (m.submodule !== undefined) {
        for (const lev1 of m.submodule) {
          if (lev1.submodule !== undefined && lev1.submodule.length == 0 && lev1.is_checked) {
            lev1.farbe = colored
            m.farbe = colored
          }
          if (lev1.submodule !== undefined) {
            for (const lev2 of lev1.submodule) {
              if (lev2.submodule !== undefined && lev2.submodule.length == 0 && lev2.is_checked) {
                lev1.farbe = colored
                lev2.farbe = colored
                m.farbe = colored
              }
              if (lev2.submodule !== undefined) {
                for (const lev3 of lev2.submodule) {
                  if (lev3.submodule !== undefined && lev3.submodule.length == 0 && lev3.is_checked) {
                    m.farbe = colored
                    lev1.farbe = colored
                    lev2.farbe = colored
                    lev3.farbe = colored

                  }
                  if (lev3.submodule !== undefined) {
                    for (const lev4 of lev3.submodule) {
                      if (lev4.submodule !== undefined && lev4.submodule.length == 0 && lev4.is_checked) {
                        m.farbe = colored
                        lev1.farbe = colored
                        lev2.farbe = colored
                        lev3.farbe = colored
                        lev4.farbe = colored

                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    })
  }


  ngOnInit() {

    console.log('ngOnInit')
    let what = ''

    this.dataService.setPfad(this.pfad)

    console.log('Route: ' + this.route.url.substring(1))

    this.dataService.navComponent.showNavBar = this.route.url.substring(1)
    this.dataService.navComponent.navChanged(this.route.url.substring(1))

    this.dataService.getModules(this.pfad).then((_) => {

      this.module = _

      console.log('Modules from storage')
      console.log(this.module)

      if (this.module === null || (this.module !== undefined && this.module.length < 1)) {
        console.log('RELOAD')
        this.loadGeneric(this.pfad)
      }

      console.log('Continue')

      this.dataService.setLanguage('de')
      this.sprache = 'de'
      this.auswahlaufheben = 'Auswahl ausblenden'
      this.auswahleinblenden = 'Auswahl einblenden'

      this.modulauswahlzurueck = 'Modulauswahl zurücksetzen'
      this.weiter = 'Weiter'

      this.backlink = this.route.url
      if (this.route.url === '/wm_module' || this.route.url === '/modulpage') {
        what = 'module'
      }


      if (this.route.url === '/wm_module_en') {
        what = 'module_en'
        this.dataService.setLanguage('en')
        this.sprache = 'en'
        this.auswahlaufheben = 'Hide selection'
        this.auswahleinblenden = 'Show selection'

        this.modulauswahlzurueck = 'Reset selection'
        this.weiter = 'Next'
      }

      if (this.route.url === '/uk_module') {
        console.log('Continue UK')
        what = 'uk_module'
      }

      if (this.route.url === '/pb_module') {
        console.log('route via ' + this.pfad)
        what = 'pb_module'
      }

      if (this.route.url === '/ik_module_de') {
        this.sprachzusatz = 'für deutschsprachige Präsentationen';
      }

      if (this.route.url === '/ik_module_en') {

        this.sprachzusatz = 'für englischsprachige Präsentationen';
      }

      this.colorizeAndCheckTree()

    })



  }


  ngAfterContentInit() {

  }

  /**
   * Prüfen, ob ein Veto auf dem Element liegt
   * @param id
   * @returns
   */
  clickIsPossible(id) {
    var returner = true
    this.vetoMap.forEach((vm) => {
      vm.references.forEach((refs) => {
        if (refs == id) {
          returner = false
        }
      })
    })
    return returner
  }

  /**
   *
   * @param modul
   */
  toggle_with_ma(modul: Modul): void {

    console.log('toggle state for ' + modul.id)
    if (this.clickIsPossible(modul.id)) {

      modul.is_checked = !modul.is_checked

      // check and highlight clicked element
      this.mustHighlightHead_with_ma(modul)

      // check/uncheck and highlight/x the clicked element
      if (modul.is_checked == true) {

        if (modul.mandatoryAggregate != undefined) {

          const vm = new VetoMap()
          vm.id = modul.id
          vm.references = modul.mandatoryAggregate
          this.vetoMap.push(vm)

          modul.mandatoryAggregate.forEach((ma) => {
            // console.log(ma)
            const ma_modul = this.getModuleByIdDeep(ma, this.module)
            ma_modul.is_checked = true
          })
        }

        this.checkFullPath(modul)

      } else {

        this.removeVeto(modul.id)
        // console.log(this.vetoMap)

        if (modul.mandatoryAggregate != undefined) {
          modul.mandatoryAggregate.forEach((ma) => {
            // console.log(ma)
            const ma_modul = this.getModuleByIdDeep(ma, this.module)
            ma_modul.is_checked = false
          })
        }
      }

      // console.log(this.module)
      this.storeLastAgendaModel(this.module);
      this.colorizeAndCheckTree()
      this.dataService.setModules(this.pfad, this.module)
    }
  }


  checkElement(modul) {
    // modul.color = '#f8bb0b'
    modul.is_checked = true
  }

  checkFullPath(modul) {

    if (modul && modul.parent >= 0) {

      this.checkElement(modul)
      const p1 = this.getModuleByIdDeep(modul.parent, this.module)

      if (p1 && p1.parent >= 0) {

        this.checkElement(p1)
        const p2 = this.getModuleByIdDeep(p1.parent, this.module)

        if (p2 && p2.parent >= 0) {

          this.checkElement(p2)
          const p3 = this.getModuleByIdDeep(p2.parent, this.module)

          if (p3 && p3.parent >= 0) {

            this.checkElement(p3)
            // console.log('>4 ' + p3.id)

          }

        }
      }
    }

  }

  showFullPath(modul: Modul, path: Modul[]) {

    let returner = []
    // console.log('showFullPath')
    // console.log(modul)

    if (modul && modul.parent >= 0) {
      returner.push(modul)
      // console.log('>1 ' + modul.parent)
      const p1 = this.getModuleByIdDeep(modul.parent, this.module)
      // console.log(p1)
      if (p1 && p1.parent >= 0) {
        returner.push(p1)
        // console.log('>2 ' + p1.id)
        const p2 = this.getModuleByIdDeep(p1.parent, this.module)

        if (p2 && p2.parent >= 0) {
          returner.push(p2)
          // console.log('>3 ' + p2.id)
          const p3 = this.getModuleByIdDeep(p2.parent, this.module)

          if (p3 && p3.parent >= 0) {
            returner.push(p3)
            // console.log('>4 ' + p3.id)

          }

        }
      }
    }

    // console.log(returner)
    // console.log('<<-FullPath')
    return returner
  }


  moduleMap: TreePathMap = new TreePathMap()

  traverseTree(e: Modul, path: Modul[]) {
    // console.log(e)
    //this.showFullPath(e)
    e.submodule.forEach((element) => {
      this.traverseTree(element, path)
    })
    //path.push(e)
    //for(let i=0; i<e.length; i++) {

    // console.log(e[i].id + ' ' + e[i].name + ' ' + e[i].color)
    // console.log(path)

    //

    //    }
  }






  removeVeto(id) {
    var index = this.vetoMap.findIndex(x => x.id == id);
    this.vetoMap.splice(index, 1);
  }


  mustHighlightHead_with_ma(modul: any) {

    /*
    const parent = this.getModuleByIdDeep(modul.parent, this.module)
    // console.log('mustHighlightHead_with_ma (off) for ' + modul.id + ', parent is ' + parent.id)

    if (modul.is_checked) {
      // console.log('mustHighlightHead_with_ma (parent off)')
      modul.color = '#f8bb0b'
      parent.color = '#f8bb0b'
    } else {
      // console.log('mustHighlightHead_with_ma (parent on)')
      modul.color = '#333333'
      parent.color = '#333333'
    }
*/
  }

  // todo1303
  mustHighlightHead(modul: any) {

    /*

    if (this.tempHeaderArray.indexOf(modul.id.valueOf()) >= 0) {
      const lblx = (<HTMLSpanElement>document.getElementById('lbl' + modul.id));
      if (lblx != null) {
        lblx.style.color = '#ff0000' //'#f8bb0b';
        // console.log(1)
      }
    }

    let parent;
    var modulesChanged = false;
    for (const entry of this.tempHeaderArray) {
      if (+entry > 0) {

        const temp: Modul = this.getSubModuleById(entry, this.module);
        if (!temp) {
          modulesChanged = true;
        } else {
          parent = temp.parent;


          if (parent > 0 && +modul.id === parent) {
            const lblx = (<HTMLSpanElement>document.getElementById('lbl' + parent));
            if (lblx != null) {
              lblx.style.color = '#f8bb0b';
              // console.log(2)
            }

          }
          // More level
          if (parent > 0) {
            const temp1: Modul = this.getSubModuleById(parent, this.module);
            const lblx = (<HTMLSpanElement>document.getElementById('lbl' + parent));
            if (lblx != null) {
              lblx.style.color = '#f8bb0b';
              // console.log(3)
            }
            parent = temp1.parent;
          }

          // More level
          if (parent > 0) {
            const temp2: Modul = this.getSubModuleById(parent, this.module);
            const lblx = (<HTMLSpanElement>document.getElementById('lbl' + parent));
            if (lblx != null) {
              lblx.style.color = '#f8bb0b';
              // console.log(4)
            }

            const pflicht: Modul = this.getSubModuleById(parent * 1000, this.module);
            if (id != pflicht.id) {
              this.addSelection(pflicht);
            }

          }
        }
      }
    }


    if (modulesChanged) {
      this.resetChoice();
    }
    */
  }


  highlightHeads() {

    let parentId: number;
    const temps = this.getIdList();
    this.tempHeaderArray = [];

    if (temps !== undefined && temps !== null) {

      // console.log('Loading ' + 'current' + this.context + ' favorites: ' + temps)
      const splitted = temps.split(';');
      for (const entry of splitted) {

        // console.log('index: ' + this.tempArray.indexOf(+entry));
        if (this.tempArray.indexOf(+entry) < 0) {
          this.tempArray.push(+entry);
        }
        parentId = +(entry.substr(0, entry.length - 3));
        this.tempHeaderArray.push(+parentId);

      }
    }

    // console.log('>>>>temps');
    // console.log(temps);
    //this.removeFromTempArray(9000)
    //this.removeFromTempArray(9011)
    for (const entry of this.tempHeaderArray) {
      // console.log(entry)
      if (('' + entry)[0] == '9') {
        //this.addToTempArray(9000)
        //this.addToTempArray(9011)
      }
    }


  }


  pfeil(modul: Modul) {
    if (modul.showSubTree === true) {
      return this.menuOpen;
    } else {
      return this.menuClosed;
    }
  }

  /*
  / TREE-CONTROL
  */

  openSubTree(modul: Modul) {

    // console.log('openRH ' + modul.showSubTree + ' >> ' + modul.regulatory + ' >> ' + this.modal);
    // console.log(modul.id + '>' + modul.level + '>' + modul.showSubTree)
    this.currentModul = modul;
    const rhSeen = this.getRegulatoryAccepted(modul);
    if ((modul.showSubTree === false || modul.showSubTree === undefined) && modul.regulatory != null && rhSeen == null) {
      this.modal = 1;
      this.regulatory = modul.regulatory;
    } else {
      this.showSubTree(modul);
    }
  }

  showSubTree(modul: Modul) {

    let newVal;
    // console.log(modul.name + ' ' + modul.level + ' ' + modul.submodule.length);
    if (modul.submodule !== undefined && modul.submodule.length > 0) {

      if (modul.showSubTree === true) {
        newVal = false;
        if (this.remainNodeOpen === false) {
          modul.showSubTree = false;
        }
      } else {
        newVal = true;
        modul.showSubTree = true;
      }

      if (modul.level === 0) {
        for (const clev1 of this.module) {
          if (this.remainNodeOpen === false) {
            clev1.showSubTree = false;
          }
        }
        modul.showSubTree = newVal;

      } else if (modul.level === 1) {
        for (const clev1 of this.module) {
          for (const clev2 of clev1.submodule) {
            if (this.remainNodeOpen === false) {
              clev2.showSubTree = false;
            }
          }
        }
        modul.showSubTree = newVal;

      } else if (modul.level === 2) {
        for (const clev1 of this.module) {
          for (const clev2 of clev1.submodule) {
            for (const clev3 of clev2.submodule) {
              if (this.remainNodeOpen === false) {
                clev3.showSubTree = false;
              }
            }
          }
        }
        modul.showSubTree = newVal;
      }
    } // if length>0
  }


  /*
  / Modal-Dialog für Regulatorische Hinweise
  */
  showModal(modul: Modul) {
    if (modul.regulatory != null) {
      this.modal = 1;
      this.regulatory = modul.regulatory;
      // console.log('open ' + modul.regulatory + ' ' + +this.modal)
    }
  }

  showModalVertriebsbox(modul: Modul) {
    if (modul.vertriebsbox != null) {
      this.modal = 1;
      this.regulatory = modul.vertriebsbox;
      // console.log('open ' + modul.regulatory + ' ' + +this.modal)
    }
  }

  closeModal() {
    this.modal = -1;
    // console.log('close ' + +this.modal)
    if (this.regulatoryAccepted === true) {
      // console.log('showSubtree')
      this.showSubTree(this.currentModul);
      this.regulatoryAccepted = false;
      this.storeRegulatoryAccepted(this.currentModul);
    }
  }


  @ViewChild('dataContainer') dataContainer: ElementRef;
  loadData(data) {
    if (this.dataContainer !== undefined) {
      this.dataContainer.nativeElement.innerHTML = data;
    }
  }

  // TODO1303
  public isChecked(m: Modul, preselectedModules: number[]): String {
    // console.log('isChecked: ' + preselectedModules);
    if (this.isInModules(m, preselectedModules)) {
      return 'check_gelb';
    } else {
      return 'check_leer';
    }
  }


  getModule(context: Mainpage): Modul[] {
    // console.log('getModule: ' + context.linktitle)
    return this.modulpageService.getModule(context)
  }

  getModuleFromJSON(what: string): Modul[] {
    // console.log('getModule: ' + what)
    return this.modulpageService.getModuleFromJSON(what)
  }


  public getModulSelection(): Promise<Modul[]> {
    // console.log('get ' + this.selectionArray.length);
    return Promise.resolve(this.module);
  }

  onSelect(modul: Modul): void {
    this.selectedModul = modul;
  }

  isOneSelected(id: number) {
    for (let entry of this.selectionArray) {
      if (entry != null && entry.parent === id) {
        return true;
      }
    }
    return false;
  }

  isANonParentSelected(id: number, steckbrief: Modul) {
    for (let entry of this.selectionArray) {
      if (entry != null && steckbrief != null && entry.parent === id && entry.id !== steckbrief.id) {
        return true;
      }
    }
    return false;
  }


  /*
  / Handle Module-Arrays
  */

  addModule(modul: Modul) {
    if (!this.moduleIsAllowed(modul)) {
      return;
    }
    if (!this.selectionArray.includes(modul)) {
      this.selectionArray.push(modul);
      this.storeAgendaModel(this.selectionArray);
      this.storeSelectionModel(this.sortModulearray(this.selectionArray));
    }
  }

  removeModule(modul: Modul) {
    const temp: Modul[] = [];

    for (let entry of this.selectionArray) {
      if (entry.id !== modul.id) {
        temp.push(entry);
        // console.log('push ' + entry.id)
      }
    }

    this.selectionArray = temp;
    this.storeAgendaModel(this.selectionArray);
    this.storeSelectionModel(this.sortModulearray(this.selectionArray));
  }

  moduleIsAllowed(modul: Modul, silent: boolean = true) {
    let hasVVEinsteiger = false;
    let hasVVProfi = false;
    for (const entry of this.selectionArray) {
      // gather information for VV check
      if (entry.parent == 8001001 || entry.parent == 8002001) {
        hasVVEinsteiger = true;
      } else if (entry.parent == 8001002 || entry.parent == 8002002) {
        hasVVProfi = true;
      }
      // check if already selected
      if (entry.id === modul.id) {
        return false;
      }
    }
    if ((modul.parent == 8001001 || modul.parent == 8002001) && hasVVProfi) {
      // no einsteiger when profi
      if (!silent) {
        alert('VV für Einsteiger kann nicht gewählt werden, da bereits VV für Profis gewählt wurde.')
      }
      return false;
    } else if ((modul.parent == 8001002 || modul.parent == 8002002) && hasVVEinsteiger) {
      // no profi when einsteiger
      if (!silent) {
        alert('VV für Profis kann nicht gewählt werden, da bereits VV für Einsteiger gewählt wurde.')
      }
      return false;
    }
    return true;
  }

  addToTempArray(n: number) {
    const index: number = this.tempArray.indexOf(n);
    if (index == -1) {
      // console.log('addToTempArray>' + n)
      this.tempArray.push(n);
    }
  }
  removeFromTempArray(n: number) {
    const index: number = this.tempArray.indexOf(n);
    if (index !== -1) {
      this.tempArray.splice(index, 1);
    }
  }





  /*
  / Regulatory-Handling
  */

  toggleRegulatory(image: any): void {
    if (image.src == this.url + this.baseHref + '/' + this.leererCheck) {
      this.regulatoryAccepted = true;
      this.buttonColor = 1;

    } else {
      this.regulatoryAccepted = false;
      this.buttonColor = 3;
    }
  }

  isRegulatoryAccepted(): string {
    if (this.regulatoryAccepted === true) {
      return this.gelberCheck;
    } else {
      return this.leererCheck;
    }
  }

  isInSelection(modul: Modul) {
    for (const entry of this.selectionArray) {
      if (entry.id === modul.id) {
        return true;
      }
    }
    return false;
  }

  removeSelection(modul: Modul) {
    this.removeModule(modul);
    this.removeFromTempArray(modul.id);
  }

  addSelection(modul: Modul) {


    this.addModule(modul);

    this.addToTempArray(modul.id);

    if (modul.mandatoryAggregate != undefined) {
      modul.mandatoryAggregate.forEach((element) => {
        this.addToTempArray(element)

        let elementToPush = this.getModuleByIdDeep(element, this.module)
        this.addModule(elementToPush);
        if (!this.selectionArray.includes(elementToPush)) {
          this.selectionArray.push(elementToPush)
        }
      })
    }



  }

  /*
    switchtoggle(image: any, modul: Modul, twinId: number): void {

      // console.log('T');
      this.toggle(image, modul);
      if (this.isInSelection(modul)) {
        const twin = this.getSubModuleById(twinId, this.module);
        this.removeSelection(twin);
        this.storeIdListFromTree(this.selectionArray);
        this.highlightHeads();
      }
    }
  */


  /*
    toggle(image: any, modul: Modul): void {


      if (image.src === this.url + this.baseHref + '/' + this.leererCheck) {
        if (!this.moduleIsAllowed(modul, false)) {
          return;
        }
        this.addSelection(modul);
        //this.togglePflicht(modul)
        image.src = this.url + this.baseHref + '/' + this.gelberCheck;
      } else {
        this.removeSelection(modul);
        image.src = this.url + this.baseHref + '/' + this.leererCheck;
      }

      // console.log('-------')
      // console.log(this.selectionArray)
      // console.log('-------')

      this.storeIdListFromTree(this.selectionArray);

      for (const entry of this.module) {

        const lbl = (<HTMLSpanElement>document.getElementById('lbl' + entry.id));
        if (lbl != null) {
          lbl.style.color = '#333';
        }

        for (const sub1entry of entry.submodule) {
          const slbl = (<HTMLSpanElement>document.getElementById('lbl' + sub1entry.id));
          if (slbl != null) {
            slbl.style.color = '#333';
          }

          for (const sub2entry of sub1entry.submodule) {
            const slbl2 = (<HTMLSpanElement>document.getElementById('lbl' + sub2entry.id));
            if (slbl2 != null) {
              slbl2.style.color = '#333';
            }
          }

        }
      }
      this.highlightHeads();
    }
  */

}

class VetoMap {
  id: number
  references: number[]
}

class TreePathMap {
  id: Modul
  references: Modul[]
}
