import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationbuilderComponent } from './navigationbuilder/navigationbuilder.component';

const routes: Routes = [{
    path: 'navbuilder',
    component: NavigationbuilderComponent,
    children: []
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class NavigationbuilderRoutingModule { }
