import { Component, OnInit } from '@angular/core';
import { GlobalEventsManager } from '../services/GlobalEventsManager';
import { Commaster } from '../models/commaster';
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [],
  templateUrl: 'close.html',
  styleUrls: []
})

export class CloseComponent extends Commaster implements OnInit {

  constructor(public dataService: DataService) {
    super(dataService);
  }

  ngOnInit() {
    this.context = this.getContext();
    //this.globalEventsManager.showNavBar(this.context);
    this.dataService.setNavState(this.context);

    localStorage.setItem('reload', 'yes');

    // console.log('reset');
    this.clearSelectionForAll();

    // todo: Das muss irgendwo anders hin!
    localStorage.setItem('modulee', '');
    localStorage.setItem('moduleuk_module', '');
    localStorage.setItem('moduleik_module', '');
    // console.log('reset');
    window.close();
  }

}
