import { Component, Input } from '@angular/core';
import { GlobalEventsManager } from './services/GlobalEventsManager';
import { Commaster } from './models/commaster';
import { DataService } from './data.service';
import { ModulpageService } from './modulpage/modulpage.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'my-nav',
    providers: [],
    templateUrl: './nav.component.html',
})

export class NavComponent extends Commaster {
    @Input() isActive: string;

    showNavBar = '';
    startpath = ''

    constructor(public dataService: DataService, private modulpageService: ModulpageService) {
        super(dataService);

        this.dataService.registerNavComponent(this)
        this.showNavBar = this.dataService.getNavState()
        this.startpath = environment.startpath

        console.log("NavState " + this.showNavBar)
    }

    public getNavigationMode() {
        return this.dataService.getNavigationMode()
    }

    public setNavigationMode(nav) {
        return this.dataService.setNavigationMode(nav)
    }

    public getActiveModule() {
        return this.dataService.getActiveModule()
    }

    public setActiveModule(nav) {
        return this.dataService.setActiveModule(nav)
    }

    public navChanged(nav: string) {
        console.log("NavState: navChanged")
        this.showNavBar = nav
    }

    public showMainNav() {
        this.showNavBar = "main"
        this.dataService.setNavState(this.showNavBar)
        this.context = ('')
        this.storeContext('')

    }

    loadModule(nav) {
        console.log("LoadModule for " + nav)
        if (nav === null || nav === undefined || nav === '') {
            this.dataService.getNavigationMode().then((nav) => {
                this.dataService.resetAuswahl()
                return this.modulpageService.getModuleFromJSON(nav)
            })
        } else {
            this.dataService.setNavigationMode(nav).then((_) => {
                this.dataService.resetAuswahl()
                return this.modulpageService.getModuleFromJSON(nav)
            })

        }

    }


    reloadData() {
        console.log("NavState: reloadData")
        localStorage.setItem('reload', 'yes');
        this.showNavBar = this.dataService.getNavState()
    }

    setModulbase(modus: string): void {
        console.log("NavState: setModulbase " + modus)
        this.modulemode = modus
        this.storeModulemode(modus);
    }
}
