import { Component, OnInit } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { WMService } from './wm.service';
import { GlobalEventsManager } from "../services/GlobalEventsManager";
import { Commaster } from '../models/commaster';
import { DataService } from '../data.service';
import { environment } from 'src/environments/environment';
import { ModulpageService } from '../modulpage/modulpage.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'my-wm',
  providers: [WMService, AppComponent],
  templateUrl: './wm.html',
  styleUrls: ['wm.component.css']
})


export class WMComponent extends Commaster implements OnInit {

  title = environment.homepage_title
  subtitle = environment.homepage_subtitle

  title_en = environment.homepage_title_en
  subtitle_en = environment.homepage_subtitle_en

  wmpages: Mainpage[];
  selectedMainpage: Mainpage;

  constructor(private wmService: WMService, private globalEventsManager: GlobalEventsManager, public dataService: DataService, private modulpageService: ModulpageService) {
    super(dataService);

    // console.log('WMComponent constructor ' + this.context + '-' + this.modulemode);

    this.storeContext('wm');
    //this.globalEventsManager.showNavBar('wm');
    this.dataService.setNavState(this.context);
  }

  getWMpages(): void {
    this.wmService.getWMpages().then(mainpages => this.wmpages = mainpages);
    this.wmService.getWMENpages().then((mainpages) => {
      this.dataService.setModules("MAINPAGE_EN", mainpages)
    })
  }

  ngOnInit(): void {

    // console.log('WMComponent ngOnInit ' + this.context + '-' + this.modulemode);

    localStorage.setItem('modulewm_module', '');
    this.getWMpages();
    // console.log('/WMComponent ngOnInit ' + this.context + '-' + this.modulemode);

  }

  onSelect(mainpage: Mainpage): void {
    this.selectedMainpage = mainpage;
  }

  reloadData(mainpage: Mainpage) {
    localStorage.setItem('reload', 'yes');
    this.dataService.setModules("MAINPAGE", mainpage)
  }

  loadModule(nav) {
    // console.log("LoadModule for " + nav)
    if (nav === null || nav === undefined || nav === '') {
      this.dataService.getNavigationMode().then((nav) => {
        this.dataService.resetAuswahl()
        return this.modulpageService.getModuleFromJSON(nav)
      })
    } else {
      this.dataService.setNavigationMode(nav).then((_) => {
        this.dataService.resetAuswahl()
        return this.modulpageService.getModuleFromJSON(nav)
      })

    }

  }
}



