import { Component, OnInit } from '@angular/core';
import { Favorit } from '../../models/favorit';
import { Commaster } from '../../models/commaster';
import { GlobalEventsManager } from '../../services/GlobalEventsManager';
import { Metadata } from '../../models/metadata';
import { Modul } from '../../models/modul';
import { DataService } from 'src/app/data.service';

@Component({

  selector: 'app-favoritenpage',
  templateUrl: './favoritenpage.component.html',
  providers: [],
  styleUrls: ['./favoritenpage.component.css']
})

export class FavoritenpageComponent extends Commaster implements OnInit {

  title_modul = 'Meine Favoriten';
  // tslint:disable-next-line:max-line-length
  subtitle_modul = 'Sie können sich hier jede erstellte Präsentation als eigenen Favoriten speichern. Es ist dadurch z. B. möglich, eine Präsentationserstellung zu unterbrechen und jederzeit an beliebiger Stelle fortzufahren oder Sie speichern häufig genutzte Vorlagen, die Sie gerne wieder verwenden möchten. Werden zwischenzeitlich Inhalte der gespeicherten Präsentationen geändert, so werden diese bei nächster Verwendung automatisch aktualisiert.';

  firstFree: number = -1;
  favoriten: Favorit[] = [];
  hint: string[] = [];

  messagetext = '';

  constructor(public dataService: DataService) {
    super(dataService);

    this.favoriten = [
      new Favorit(0, '', '', '', '', '', '', false, '', null, null),
      new Favorit(1, '', '', '', '', '', '', false, '', null, null),
      new Favorit(2, '', '', '', '', '', '', false, '', null, null),
      new Favorit(3, '', '', '', '', '', '', false, '', null, null),
      new Favorit(4, '', '', '', '', '', '', false, '', null, null),
      new Favorit(5, '', '', '', '', '', '', false, '', null, null),
      new Favorit(6, '', '', '', '', '', '', false, '', null, null),
      new Favorit(7, '', '', '', '', '', '', false, '', null, null),
      new Favorit(8, '', '', '', '', '', '', false, '', null, null),
      new Favorit(9, '', '', '', '', '', '', false, '', null, null)];

    const num = 10;
    let i: number;
    for (i = 0; i < num; i++) {
      if (localStorage.getItem('favname' + i) === null) {
        this.deleteFav(i);
      }
    }
  }


  ngOnInit() {

    this.context = this.getContext();
    this.modulemode = this.getModulemode();
    //this.globalEventsManager.showNavBar(this.context);
    this.dataService.setNavState(this.context);
    //this.nav.refresh(this.modulemode)

    const num = 10;
    let i: number;

    for (i = 0; i < num; i++) {
      this.favoriten[i].content = localStorage.getItem('store' + i);
      this.favoriten[i].context = localStorage.getItem('context' + i);
      this.favoriten[i].modulemode = localStorage.getItem('modulemode' + i);
      this.favoriten[i].name = localStorage.getItem('favname' + i);
      this.favoriten[i].lastchange = localStorage.getItem('lastchange' + i);
      this.favoriten[i].kundendaten = localStorage.getItem('kundendaten' + i);
      this.favoriten[i].selectionModel = JSON.parse(localStorage.getItem('selectionModel' + i));
      this.favoriten[i].metadata = JSON.parse(localStorage.getItem('metadata' + i));
      this.hint[i] = null;
    }

    console.log("Favoriten from storage")
    console.log(this.favoriten)
    //this.sortFavs();

    for (i = 0; i < num; i++) {
      if (this.firstFree < 0 && this.favoriten[i].name === '') {
        this.firstFree = i;
      }
      localStorage.setItem('store' + i, this.favoriten[i].content);
      localStorage.setItem('favname' + i, this.favoriten[i].name);
      localStorage.setItem('lastchange' + i, this.favoriten[i].lastchange);
      localStorage.setItem('context' + i, this.favoriten[i].context);
      // console.log('mm' + i + this.favoriten[i].modulemode);
      localStorage.setItem('modulemode' + i, this.favoriten[i].modulemode);
      localStorage.setItem('kundendaten' + i, this.favoriten[i].kundendaten);
      localStorage.setItem('selectionModel' + i, JSON.stringify(this.favoriten[i].selectionModel));
      localStorage.setItem('metadata' + i, JSON.stringify(this.favoriten[i].metadata));
    }
    if (this.favoriten[this.firstFree] !== undefined) {
      this.favoriten[this.firstFree].background = 'f00';
    }

    // console.log("fav: " + this.getIdList())
    // console.log("ff: " + this.firstFree)
    localStorage.setItem('current' + this.modulemode, this.getIdList());
    /*
    this.messagetext = localStorage.getItem('favtext');
    if (this.messagetext !== '') {
      this.showSnack();
    }
    */
  }

  mapModulemode(mm: any) {
    if (mm === 'wm_module') {
      return "WM-Modul (deutsch)"
    } else if (mm === 'wm_module_en') {
      return "WM-Module (english)"
    } else if (mm === 'uk_module') {
      return "UK-Modul (deutsch)"
    } else if (mm === 'ik_module') {
      return "IK-Modul (deutsch)"
    } else if (mm === 'ik_module_en') {
      return "IK-Module (english)"
    } else if (mm === 'pb_module') {
      return "PB-Modul"
    } else
      return mm;
  }


  dump(module: Modul[]) {
    let result = '';
    if (module === null) {
      return '';
    }
    for (let f of module) {
      result += f.id + ' ';
    }
    return result;
  }

  getBackground(i: number): string {
    if (i === this.firstFree) {
      return '#fbe041';
    } else {
      return '#fff';
    }
  }


  sortFavs() {

    this.favoriten.sort((a: any, b: any) => {
      if (a.lastchange > b.lastchange) {
        return -1;
      } else if (a.lastchange < b.lastchange) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  storeFav(i) {

    const metadata: Metadata = Metadata.getMetadata(this.context);

    this.dataService.getActiveModule().then((activePfad) => {

      console.log("Favoriten speichern")
      this.dataService.getFavData().then((fav) => {
        console.log(fav)
        localStorage.setItem('favname' + i, this.favoriten[i].name);
        localStorage.setItem('context' + i, fav['path']);
        localStorage.setItem('treestate' + i, JSON.stringify(fav));
        localStorage.setItem('modulemode' + i, activePfad);
        localStorage.setItem('metadata' + i, JSON.stringify(metadata));
        localStorage.setItem('kundendaten' + i, JSON.stringify(metadata.customerdata));
        localStorage.setItem('selectionModel' + i, JSON.stringify(this.getSelectionModel()));
        localStorage.setItem('showTextblock' + i, '' + metadata.showTextblock);
        localStorage.setItem('showWeiterempfehlungpage' + i, '' + metadata.showWeiterempfehlungpage);
        localStorage.setItem('showContactpage' + i, '' + metadata.showContactpage);
        localStorage.setItem('showAnlageberatung' + i, '' + metadata.showAnlageberatung);
        localStorage.setItem('deckblatt' + i, metadata.deckblatt);

        this.hint[i] = 'Favorit wurde gespeichert';

      })

    })

  }

  loadFav(i, context: string) {
    console.log("load nav")

    const context1 = localStorage.getItem('context' + i)
    console.log(context1)
    //this.dataService.setPfad(context1)
    this.dataService.setNavState(context1)


    this.dataService.setModules("AUSWAHL", (JSON.parse(localStorage.getItem('treestate' + i))))
    this.storeContext(context1);
    this.storeModulemode(localStorage.getItem('modulemode' + i));
    this.storeIdList(localStorage.getItem('store' + i), localStorage.getItem('modulemode' + i));
    this.storeSelectionModel(JSON.parse(localStorage.getItem('selectionModel' + i)));
    localStorage.setItem('reload', 'no');

    const storedMetaData: Metadata = JSON.parse(localStorage.getItem('metadata' + i));

    const metadata = Metadata.getClearMetadata(context1);
    metadata.customerdata = JSON.parse(localStorage.getItem('kundendaten' + i));
    metadata.ausgangslage = storedMetaData.ausgangslage; // localStorage.getItem('ausgangslage' + i);
    metadata.schwerpunkte = storedMetaData.schwerpunkte; // localStorage.getItem('schwerpunkte' + i);
    metadata.showTextblock = storedMetaData.showTextblock;  // +localStorage.getItem('showTextblock' + i);
    metadata.showWeiterempfehlungpage = storedMetaData.showWeiterempfehlungpage; // +localStorage.getItem('showWeiterempfehlungpage' + i);
    metadata.showContactpage = storedMetaData.showContactpage; //+localStorage.getItem('showContactpage' + i);
    metadata.showAnlageberatung = storedMetaData.showAnlageberatung; //+localStorage.getItem('showAnlageberatung' + i);
    metadata.deckblatt = storedMetaData.deckblatt; //localStorage.getItem('deckblatt' + i);

    metadata.storeMetadata(context1);

    //this.nav.refresh("uk_module");

    // console.log(localStorage.getItem('selectionModel' + i));
    // console.log(this.getSelectionModel());

    //alert(this.favoriten[i].name + " wird geladen (" + i + ")" + localStorage.getItem('store' + i));
  }

  deleteFav(i) {

    localStorage.setItem('store' + i, '');
    localStorage.setItem('favname' + i, '');
    localStorage.setItem('context' + i, '');
    localStorage.setItem('modulemode' + i, '');
    localStorage.setItem('lastchange' + i, '0');
    localStorage.setItem('kundendaten' + i, '0');
    localStorage.setItem('ausgangslage' + i, '0');
    localStorage.setItem('schwerpunkte' + i, '0');
    localStorage.setItem('showTextblock' + i, '0');
    localStorage.setItem('selectionModel' + i, '0');
    localStorage.setItem('storeSelectionModel' + i, '0');
    localStorage.setItem('showWeiterempfehlungpage' + i, '0');
    localStorage.setItem('showContactpage' + i, '-1');
    localStorage.setItem('deckblatt' + i, '0');

    this.favoriten[i].content = '';
    this.favoriten[i].context = '';
    this.favoriten[i].name = '';
    this.favoriten[i].modulemode = '';
    this.favoriten[i].lastchange = '';
    this.favoriten[i].kundendaten = '';
    this.favoriten[i].selectionModel = [];

    //alert('Favorit wurde gelöscht');

  }

  currentDateTime() {
    const displayDate = new Date().toLocaleDateString();
    const displayTime = new Date().toLocaleTimeString();
    return displayDate + ' ' + displayTime;
  }

  getTimestamp(): string {
    // tslint:disable-next-line:max-line-length
    const timeStampInMs = window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
    // console.log(timeStampInMs)
    return timeStampInMs.toString();
  }

  /*
  showSnack() {

      // Get the snackbar DIV
      const x = document.getElementById('snackbar');

      // Add the "show" class to DIV
      x.className = 'show';

      // After 3 seconds, remove the show class from DIV
      setTimeout(function(){ x.className = x.className.replace('show', ''); }, 3000);

      localStorage.setItem('favtext', '');
  }
*/

}
