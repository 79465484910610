import { Injectable } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { ADMINPAGES } from '../definitions_/adminpages';

@Injectable()
export class AdminpageService {
  getAdminpages(): Promise<Mainpage[]> {
    return Promise.resolve(ADMINPAGES);
  }
}
