import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Modul } from './models/modul';
import { Md5 } from 'ts-md5/dist/md5';
import { NavComponent } from './nav.component';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private storage: Storage) {
    storage = new Storage({ name: "__mydb" });
  }
  navComponent: NavComponent
  language: string
  auswahl: Modul[]

  navState: string = "wm"

  public init() {

    this.storage.create();
  }

  public getFullState() {
    // console.log("getFullState")
    return this.storage.get("LANGUAGE").then((lang) => {
      // console.log(lang)
      this.language = lang

      return this.storage.get("AUSWAHL").then((auswahl) => {
        this.auswahl = auswahl
      })
    })
  }

  public getNavigationMode() {
    return this.storage.get("navigation")
  }

  public setNavigationMode(nav) {
    return this.storage.set("navigation", nav)
  }

  public getActiveModule() {
    return this.storage.get("activeModule")
  }

  public setActiveModule(nav) {
    return this.storage.set("activeModule", nav)
  }

  public getNavigationPath() {
    return this.storage.get("navigationPath")
  }

  public setNavigationPath(nav) {
    return this.storage.set("navigationPath", nav)
  }

  public set(key, value) {
    return this.storage.set(key, value)
  }

  public async resetAuswahl() {
    // console.log("-------------------------------")
    // console.log("<<<- Auswahl deleted---")
    // console.log("-------------------------------")
    // TODO 2024-04-03

    return this.storage.remove("")
  }


  public async getModules(name: string) {

    console.log("-------------------------------")
    console.log("<<<-Read Module " + name + " ---")
    console.log("-------------------------------")

    console.log("Chksum of last loaded data:")
    const chksum = await this.storage.get("chksum" + name)
    console.log("get chksum" + name)
    console.log(chksum)

    console.log("Loading data from storage")
    const data = await this.storage.get(name)
    console.log(data)

    console.log("Calculating chksum of loaded data")
    const currentChksum = Md5.hashStr(JSON.stringify(data))
    console.log(currentChksum)

    console.log("Comparing chksums")
    if (chksum !== currentChksum) {
      console.log(chksum + "!==" + currentChksum)
      console.log("Diff in chksum, reload data " + name)
      return ""

    } else {
      console.log("Chksum: " + currentChksum)
      console.log("Getting data from storage")
      return data

    }

  }


  public setModules(name, toSave) {

    if (toSave && this.storage) {
      return this.storage.set(name, toSave).then((_) => {
        // console.log("-------------------------------")
        console.log(">>>-Modules " + name + " written---")
        console.log(toSave)
        console.log(Md5.hashStr(JSON.stringify(toSave)))
        console.log("-------------------------------")
        this.storage.set("chksum" + name, Md5.hashStr(JSON.stringify(toSave)))

        this.storage.set("AUSWAHL", toSave).then((_) => {
          //toSave['path'] = name
          console.log(">>>-FAV-Temp written---")
          console.log(toSave)
          console.log("-------------------------------")
        });


      });

    }

  }

  public registerNavComponent(navComponent: NavComponent) {
    this.navComponent = navComponent
  }

  public async getPfad() {
    const returner = this.storage.get("pfad")
    return returner
  }

  public setPfad(toSave) {
    return this.storage.set("pfad", toSave).then((_) => {
    });
  }

  public async getFavData() {
    return this.storage.get("AUSWAHL").then((ret) => {
      return ret
    })
  }

  public getNavState() {
    // console.log("getNavState " + this.navState)
    return this.navState
  }

  public setNavState(navState: string) {
    console.log("setNavState " + navState)
    if (navState != "") {
      //this.navState = navState
      //this.navComponent.navChanged()
    }
  }

  public getLanguage() {
    return this.storage.get('language')
  }

  public async setLanguage(language: string) {

    await this.storage.set('language', language).then((_) => {
      // console.log("setLanguage to " + language)
    })
  }
}
