import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }    from '@angular/forms';

import { NavigationbuilderRoutingModule } from './navigationbuilder-routing.module';
import { NavigationbuilderComponent } from './navigationbuilder/navigationbuilder.component';

@NgModule({
  imports: [
    CommonModule,
    NavigationbuilderRoutingModule,
    FormsModule
  ],
  declarations: [NavigationbuilderComponent]
})
export class NavigationbuilderModule { }
