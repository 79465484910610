import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainpageRoutingModule } from './mainpage-routing.module';


@NgModule({
  imports: [
    CommonModule,
    MainpageRoutingModule
  ],
  
  declarations: []
})

export class MainpageModule implements OnInit{ 

    constructor() { 
    }
    
    ngOnInit(): void {
      
    }



}
