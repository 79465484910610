import { Modul } from './modul';
import { Metadata } from './metadata';

export class Favorit {

  constructor(
    public id: number,
    public name: string,
    public lastchange: string,
    public context: string,
    public modulemode: string,
    public content: string,
    public background: string = '#fff',
    public focus: boolean = false,
    public kundendaten: string,
    public selectionModel: Modul[],
    public metadata: Metadata
  ) {
    // console.log( id );
  }

}

