import { Component, OnInit } from '@angular/core';
import { Mainpage } from '../models/mainpage';
import { EnglishService } from './english.service';
import { GlobalEventsManager } from "../services/GlobalEventsManager";
import { Commaster } from '../models/commaster';
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [EnglishService],
  templateUrl: './english.html',
  styleUrls: ['english.component.css']
})


export class EnglishComponent extends Commaster implements OnInit {

  title = 'Herzlich Willkommen im Wealth Management';
  subtitle = "Ihre Unterstützung bei der Erstellung professioneller Kundenpräsentationen.";
  wmpages: Mainpage[];
  selectedMainpage: Mainpage;

  constructor(private wmService: EnglishService, public dataService: DataService) {
    super(dataService);

    // console.log('EnglishComponent constructor ' + this.context + '-' + this.modulemode);

    this.storeContext('wm');
    //this.globalEventsManager.showNavBar('wm');
    this.dataService.setNavState('wm');


    // console.log('/WMComponent constructor ' + this.context + '-' + this.modulemode);

  }

  getWMpages(): void {
    this.wmService.getWMpages().then(mainpages => this.wmpages = mainpages);
  }

  ngOnInit(): void {

    // console.log('WMComponent ngOnInit ' + this.context + '-' + this.modulemode);

    localStorage.setItem('modulewm_module', '');
    this.getWMpages();

    // console.log('/WMComponent ngOnInit ' + this.context + '-' + this.modulemode);

  }

  onSelect(mainpage: Mainpage): void {
    this.selectedMainpage = mainpage;
  }

  reloadData() {
    localStorage.setItem('reload', 'yes');
  }
}



