import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { GlobalEventsManager } from '../services/GlobalEventsManager';
import { Textblock } from '../models/textblock';
import { TextblockService } from '../textblock/textblock.service';
import { Commaster } from '../models/commaster';
import { SharedService } from '../services/shared.service';
import { Metadata } from '../models/metadata';
import { DataService } from '../data.service';

@Component({
  selector: 'my-app',
  providers: [TextblockService],
  templateUrl: 'textblock.html',
  styleUrls: ['textblock.component.css']
})

export class TextblockComponent extends Commaster implements OnInit {

  sprache = 'de'
  title = 'Ihre Ausgangslage und was Sie von dieser Unterlage erwarten dürfen';
  subtitle = '';

  title_en = 'Status quo and what to expect from the presentation';
  subtitle_en = '';

  ausgangslage = '';
  schwerpunkte = '';
  context = '';
  showTextblock: number = -1;

  modulauswahlzurueck = ''
  weiter = ''
  zurueck = ''

  check1 = ''
  check2 = ''
  box1 = ''
  box2 = ''
  boxtext1 = ''
  boxtext2 = ''

  constructor(private sharedService: SharedService, public dataService: DataService, @Inject(DOCUMENT) private document) {
    super(dataService);
  }

  ngOnInit() {
    this.context = this.getContext();
    this.modulemode = this.getModulemode();

    //this.globalEventsManager.showNavBar(this.context);
    this.dataService.setNavState(this.context);

    this.dataService.getLanguage().then((lang) => {
      if (lang !== null && lang !== undefined && lang !== '') {
        this.sprache = lang
      } else {
        this.sprache = 'de'
      }
      if (this.sprache === 'de') {
        this.modulauswahlzurueck = "Modulauswahl zurücksetzen"
        this.weiter = 'Weiter'
        this.zurueck = 'Zurück'
        this.check1 = 'Diese Folie in der Präsentation speichern'
        this.check2 = 'Auswahl Textbausteine'
        this.box1 = 'Ihre Ausgangslage und Erwartungen'
        this.box2 = 'Die Schwerpunkte diese Unterlage'
        this.boxtext1 = 'Freitext als Aufzählung (max. 500 Zeichen)'
        this.boxtext2 = 'Freitext als Aufzählung (max. 500 Zeichen) '

      } else {
        this.modulauswahlzurueck = "Reset selection"
        this.weiter = 'Next'
        this.zurueck = 'Back'
        this.check1 = 'Save slide in presentation'
        this.check2 = 'Selection of text modules'
        this.box1 = 'Status quo and expectations'
        this.box2 = 'Main focus of this presentation'
        this.boxtext1 = 'Your input in bullet points (maximum 500 characters)'
        this.boxtext2 = 'Your input in bullet points (maximum 500 characters)'
      }
    })

    const metadata = Metadata.getMetadata(this.getContext());
    this.ausgangslage = metadata.ausgangslage;
    this.schwerpunkte = metadata.schwerpunkte;
    this.showTextblock = metadata.showTextblock;
  }

  public isChecked(check: number): String {
    if (check > 0) {
      return this.url + this.baseHref + "/" + this.gelberCheck;
    } else {
      return this.url + this.baseHref + "/" + this.leererCheck;
    }
  }

  simpleToggle(image: any): void {
    super.simpleToggle(image);
    if (image.src === this.url + this.baseHref + "/" + this.leererCheck) {
      this.showTextblock = -1;
      this.ausgangslage = '';
      this.schwerpunkte = '';
    } else {
      this.showTextblock = 1;
    }
    this.storeText();
  }

  storeText(): void {

    const metadata: Metadata = Metadata.getMetadata(this.getContext());

    // console.log(metadata);
    /*
        if (metadata === undefined) {
    
        }
    */
    metadata.ausgangslage = this.ausgangslage;
    metadata.schwerpunkte = this.schwerpunkte;
    metadata.showTextblock = this.showTextblock;
    metadata.storeMetadata(this.context);
  }
}



