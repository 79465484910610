import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  Images: Image[] = [
    {
      "title": "Herzlich Willkommen im ComBook",
      "subtitle": "Ihre Anwendung für individuelle Kundenpräsentationen",
      "url": "./assets/images/Header_1.jpg",
      "index": 0
    },
    {
      "title": "Ihre Vermögensverwaltung neu im Combook -",
      "subtitle": "Zielgruppenorientiert. Verständlich. Und kompakt!",
      "url": "./assets/images/Header_2.jpg",
      "index": 1
    },
    {
      "title": "Megatrends verändern unser Alltagsleben rasant -",
      "subtitle": "Strukturell überproportionales Wachstum und Chancen für unsere Kunden",
      "url": "./assets/images/Header_3.jpg",
      "index": 2
    }
  ];
  //currentAnimation: string = "carousel 15s 0s infinite";
  currentLeft: string= "0%";
  currentTimeout: any;

  constructor() { }

  ngOnInit() {
    this.rotatePicture();
  }

  private rotatePicture() {
    this.currentTimeout = setTimeout(()=>{    //<<<---    using ()=> syntax
      var thisLeft:number = parseInt(this.currentLeft.replace("%",""));
      var currentIndex:number = thisLeft/-100;
      this.clickRight(currentIndex);
 }, 5000);
  }

  public clickRight(current: number) {
    if (current+1 >= this.Images.length) this.currentLeft = "0%";
    else this.currentLeft = ((current+1)*-100)+"%";
    clearTimeout(this.currentTimeout);
    this.rotatePicture();
  }

  public clickLeft(current: number) {
    if (current-1 < 0) this.currentLeft = ((this.Images.length-1)*-100)+"%";
    else this.currentLeft = ((current-1)*-100)+"%";
    clearTimeout(this.currentTimeout);
    this.rotatePicture();
  }

  public getLeft() {
    return this.currentLeft;
  }

}

export interface Image {
  title: string;
  subtitle: string;
  url: string;
  index: number;
}